<template lang="">
    <div class="result-page">
        <Result />
    </div>
</template>

<script>
import Result from '../../components/Web/Result';

export default {
    components: {
        Result,
    },
};
</script>

<style scoped lang="scss">
.result-page {
    width: 100%;
    max-width: 1470px;
    padding: 0 15px;
    margin: 80px auto 80px;
}
</style>
